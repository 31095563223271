<template>
  <page-layout>
    <span slot="header-name">{{ watchlistFormTitle }}</span>
    <common-tabs slot="header-actions" v-model="tab" :items="tabs" :converter="$tfo" class="item-tabs"></common-tabs>
    <div slot="content">
      <el-form
        v-show="tab === 'info'"
        v-loading="loading"
        :size="$vars.sizes.form"
        :label-position="$vars.forms.label_position"
        :labelWidth="$vars.forms.label_width"
        ref="form"
        :model="item"
        :rules="rules"
        :name="$options.name"
      >
        <el-form-item :label="$tf('common.label')">
          <el-color-picker name="color" v-model="color"></el-color-picker>
        </el-form-item>
        <el-form-item :label="$tf('common.id')" v-if="item.id">
          <span name="id">{{ item.id }}</span>
        </el-form-item>
        <el-form-item :label="$tf('common.name')" prop="name">
          <el-input name="name" v-model="item.name"></el-input>
        </el-form-item>
        <el-form-item prop="camera_groups" :label="$tf('camera_groups')">
          <el-select
            name="camera-groups"
            v-model="item.camera_groups"
            multiple
            :multiple-limit="10"
            :placeholder="$tf('common.not_selected')"
            style="width: 20rem"
          >
            <el-option
              v-for="item in $store.state.camera_groups.items"
              :key="'cameragroup-' + item.id"
              :label="$filters.shortString(item.name)"
              :value="item.id"
            >
              <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
                <span>{{ item.name | shortString }}</span>
              </el-tooltip>
            </el-option>
          </el-select>
          <div class="parameter-description">
            {{ $tf('parameter.watch_list_camera_groups_desc') }}
          </div>
        </el-form-item>
        <el-form-item :label="$tf('common.comment')" prop="comment">
          <el-input name="comment" v-model="item.comment"></el-input>
        </el-form-item>
        <el-form-item :label="$tfo('confidence_threshold')" v-if="item.id !== -1">
          <threshold-selector :item="item"></threshold-selector>
          <div class="parameter-description">{{ $tf('threshold_warning') }}</div>
        </el-form-item>
        <el-form-item prop="notify">
          <el-checkbox name="acknowledge" v-model="item.acknowledge">{{ $tfo(['common.require', 'common.event_acknowledgement']) }}</el-checkbox>
        </el-form-item>
        <el-form-item prop="acknowledge">
          <el-checkbox name="notify" v-model="item.notify">{{ $tfo(['common.enable', 'common.sound_aj,,2', 'common.alert']) }}</el-checkbox>
        </el-form-item>
        <el-form-item prop="ignoreEvents">
          <el-checkbox name="ignore" v-model="item.ignore_events">{{ $tf('ignore_events') }}</el-checkbox>
        </el-form-item>
        <el-form-item prop="active">
          <el-checkbox name="active" v-model="item.active" :disabled="readonly">{{ $tf('common.active') }}</el-checkbox>
        </el-form-item>
        <el-form-item prop="remoteUrl" :label="$tf('remote | url')" v-if="item.remote_url">
          <el-input name="name" v-model="item.remote_url" :disabled="true"></el-input>
        </el-form-item>
      </el-form>

      <div v-show="tab === 'permissions'" v-loading="loading">
        <el-table name="permissions-table" class="permissions-table" :size="$vars.sizes.table || $vars.sizes.common" stripe :data="groupItems">
          <el-table-column prop="name" :label="$tf('name')">
            <template slot-scope="{ row }">
              <span>{{ row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="" :align="'center'" :label="$tfo('none')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="''" :value="!item.permissions[row.id] && ''" @change="changePermissionHandler(row, '')" :disabled="isDisabled(row)"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('view')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'view'" :value="item.permissions[row.id]" @change="changePermissionHandler(row, 'view')" :disabled="isDisabled(row)"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" :align="'center'" :label="$tfo('change')" width="100px">
            <template slot-scope="{ row }">
              <div class="text-center">
                <el-radio :label="'edit'" :value="item.permissions[row.id]" @change="changePermissionHandler(row, 'edit')" :disabled="isDisabled(row)"
                  >&nbsp;
                </el-radio>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="mar-top--large">
        <el-button name="save-btn" type="primary" @click="saveHandler" :disabled="$hasNoPermission('ffsecurity.change_watchlist')"
          >{{ $tf('common.save') }}
        </el-button>

        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('common.back') }}</el-button>
        <div v-if="$store.state.config.plugins.sync && isCurrentUserAdmin() && !create" style="margin-top: 1rem">
          <el-button v-if="item.remote_url" name="remove-sync-btn" type="info" @click="deleteSyncHandler">{{ $tf('delete | synchronization,,1') }}</el-button>
          <el-button v-else name="add-sync-btn" type="info" @click="showSyncDialogHandler">{{ $tf('add | synchronization,,1') }}</el-button>
        </div>
      </div>

      <div class="mar-top--large">
        <confirm-button
          :header="$tf('delete_all_watch_lists_confirm | it_may_take_some_time')"
          :title="$tfo('delete | all | cards')"
          size="mini"
          plain
          @confirm="deleteAllHandler"
          :disabled="confirmButtonDisabled"
          v-if="!create && !readonly"
        >
        </confirm-button>

        <confirm-button
          target="watch_list"
          size="mini"
          plain
          @confirm="deleteHandler"
          :disabled="$hasNoPermission('ffsecurity.delete_watchlist')"
          v-if="!create && !readonly"
        >
        </confirm-button>
      </div>

      <el-dialog :visible.sync="syncDialog" :title="$tf('add | synchronization,,1')" :modal="true" class="image--dialog el-dialog--wide">
        <el-form
          v-loading="loading"
          :size="$vars.sizes.form"
          :label-position="$vars.forms.label_position"
          :labelWidth="$vars.forms.label_width"
          :name="$options.name"
          :model="syncItem"
          :rules="syncRules"
          ref="syncForm"
        >
          <el-form-item prop="remote_url" :label="$tf('remote | url')">
            <el-input name="remoteUrl" v-model="syncItem.remote_url"></el-input>
          </el-form-item>
          <el-form-item prop="remote_watch_list" :label="$tf('remote | watch_list')">
            <el-input name="remoteWatchlist" v-model="syncItem.remote_watch_list"></el-input>
          </el-form-item>

          <el-button class="mar-top--2" name="add-sync-btn" type="primary" @click="validateSyncHandler">{{ $tf('save') }}</el-button>
        </el-form>
      </el-dialog>
    </div>
  </page-layout>
</template>

<script>
import _ from '@/apps/common/lodash';
import PageLayout from '@/components/page/layout';
import { getUrlValidator, getNumberValidator } from '@/apps/common/validator';
import ConfidenceSelectProxy from '../common/confidence.select.proxy';

import ConfirmButton from '../confirm/confirm-button';
import ThresholdSelector from '../common/threshold.selector';

const baseRules = {
    name: [{ required: true, message: 'error.required.field', trigger: 'change' }]
  },
  syncRules = {
    remote_url: [
      { required: true, message: 'error.required.field', trigger: 'change' },
      { validator: getUrlValidator(), message: 'error.field.url', trigger: 'change' }
    ],
    remote_watch_list: [
      {
        required: true,
        validator: getNumberValidator({ required: true, unsigned: true, min: 0, max: 10000 }),
        tmessage: 'error.field.uint_max_min',
        trigger: 'change'
      }
    ]
  },
  emptySyncItem = { remote_url: '', slave_watch_list: null, remote_watch_list: null };

export default {
  name: 'dossier-list-form',
  components: { ThresholdSelector, ConfidenceSelectProxy, ConfirmButton, PageLayout },
  data: function () {
    return {
      tab: 'info',
      rules: this.$applyRuleMessages(baseRules),
      syncRules: this.$applyRuleMessages(syncRules),
      create: true,
      item: {},
      loading: false,
      syncDialog: false,
      syncItem: _.cloneDeep(emptySyncItem)
    };
  },
  computed: {
    watchlistFormTitle() {
      return this.create ? this.$tfo('create | watch_list') : this.$tfo('edit | watch_list');
    },
    tabs() {
      const Tabs = [{ name: 'info' }, { name: 'permissions' }];
      return this.$store.getters.hasAcl ? Tabs : Tabs.slice(0, 1);
    },
    groupItems() {
      return [...this.$store.state.groups.items].sort((a, b) => (a.id === this.userPrimaryGroup ? -100 : a.id - b.id));
    },
    userPrimaryGroup() {
      return this.$store.state.users.current.primary_group;
    },
    readonly() {
      return this.item && this.item.id < 0;
    },
    state() {
      return this.$store.state.watch_lists;
    },
    confirmButtonDisabled() {
      return this.$hasNoPermission('ffsecurity.delete_watchlist');
    },
    color: {
      get: function () {
        return this.item.color ? '#' + this.item.color : this.item.color;
      },
      set: function (value) {
        this.item.color = (value || '').substring(1);
      }
    }
  },
  created() {
    this.item = _.cloneDeep(this.state.item.empty);
    this.load();
    this.$store.dispatch(this.$store.state.groups.Action.Get);
  },
  methods: {
    isDisabled(row) {
      return (this.userPrimaryGroup !== 1 && row.id === this.userPrimaryGroup) || this.item.id === -1;
    },
    load() {
      let id = this.$route.params.id;
      this.create = !id;
      if (id) {
        this.loading = true;
        this.$store
          .dispatch(this.state.Action.Get, { id: decodeURIComponent(id) })
          .then((v) => {
            if (!v.camera_groups) v.camera_groups = []; // @todor after camera_groups
            this.item = v;
          })
          .catch((e) => {
            this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e } }) });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changePermissionHandler(group, v) {
      this.$set(this.item.permissions, group.id, v);
      if (!v) this.item.permissions[group.id] = null;
    },
    saveHandler(e) {
      this.$refs.form.validate(this.validateHandler);
    },
    validateHandler(valid) {
      if (!valid) return;
      this.item.labels = (this.labels || []).reduce((m, v) => {
        m[v] = 'true';
        return m;
      }, {});
      this.save(this.item);
    },
    save(item) {
      let action = this.create ? this.state.Action.Create : this.state.Action.Update;
      this.$store
        .dispatch(action, item)
        .then((v) => {
          this.create = false;
          this.item.id = v.id;
          this.item.permissions = v.permissions;
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.replace({ path: `/watch-lists/${v.id}/` });
          this.$store.dispatch('syncStateItems', 'watch-lists');
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteHandler(e) {
      this.$store
        .dispatch(this.state.Action.Delete, this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.push({ path: '/watch-lists/' });
          this.$store.dispatch('syncStateItems', 'watch-lists');
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteAllHandler(e) {
      this.$store
        .dispatch(this.state.Action.DeleteAllCardsByWatchlist, { id: this.item.id })
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    cancelHandler(e) {
      this.$router.backTo({ path: '/watch-lists/' });
    },
    showSyncDialogHandler(e) {
      this.syncDialog = true;
    },
    validateSyncHandler(e) {
      this.$refs.syncForm.validate((r) => {
        r && this.addSyncHandler();
      });
    },
    addSyncHandler(e) {
      let r = _.cloneDeep(this.syncItem);
      r.remote_watch_list = parseInt(r.remote_watch_list);
      r.slave_watch_list = this.item.id;

      this.$store
        .dispatch('addSync', r)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.syncDialog = false;
          this.syncItem = _.cloneDeep(emptySyncItem);
          this.$refs.syncForm.resetFields();
          this.load();
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    deleteSyncHandler(e) {
      this.$store
        .dispatch('deleteSync', this.item)
        .then((v) => {
          this.$notify({ type: 'success', message: this.$tf('action | success') });
          this.$router.push({ path: '/dossier-lists/' });
          this.$store.dispatch('syncStateItems', 'dossier-lists');
        })
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    isCurrentUserAdmin() {
      const hasPrimaryGroupAdmin = this.$store.state.users.current.primary_group === 1,
        hasGroupAdmin = (this.$store.state.users.current.groups || []).findIndex((v) => v.id === 1) > -1;
      return hasPrimaryGroupAdmin || hasGroupAdmin;
    }
  }
};
</script>
